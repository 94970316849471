import { useState } from "react";
import styled from "styled-components";

const AIChatBotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 2rem;
  background: transparent;
  cursor: pointer;
  border-radius: 100%;
  color: white;
  position: fixed;
  bottom: 1rem;
  right: 0.5rem;
  z-index: 1003;
  @media screen and (max-width: 1390px) {
    bottom: 3.8rem;
  }
  @media screen and (max-width: 768px) {
    bottom: 1rem;
  }
`;

const AIChatBotIFrame = styled.iframe<{ $collapsed: boolean }>`
  border: none;
  position: fixed;
  border-radius: 1.5%;
  bottom: 4rem;
  right: 2rem;
  background: black;
  width: min(400px, 100dvw);
  height: min(100dvh, 600px);
  display: ${(props) => (props.$collapsed ? "block" : "none")};
  z-index: 1002;
  @media screen and (max-width: 1390px) {
    bottom: 7rem;
  }
  @media screen and (max-width: 768px) {
    bottom: 4rem;
  }
`;

function QubeBotIcon(): JSX.Element {
  return (
    <svg
      width="72"
      height="85"
      viewBox="0 0 454 519"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 129.867L226.751 0L453.502 129.867V389.601L226.751 518.288L0 389.601V129.867Z"
        fill="white"
      />
      <path
        d="M14.2529 138.27L226.751 16.8442L439.249 138.27V381.122L226.751 501.443L14.2529 381.122V138.27Z"
        fill="#5E5E63"
      />
      <path
        d="M44.0542 155.076L226.751 50.5327L409.447 155.076V364.162L226.751 467.754L44.0542 364.162V155.076Z"
        fill="#0D0D0D"
      />
      <path
        d="M347.611 271.331L298.667 243.092L249.722 271.331L248.817 271.839L299.076 301.151L348.544 271.881L347.611 271.331Z"
        fill="#EFEFEF"
      />
      <path
        d="M248.808 271.839V328.896L297.738 357.135L298.671 357.671L299.067 301.094L248.808 271.839Z"
        fill="#36363C"
      />
      <path
        d="M348.53 271.881L299.063 301.15L298.667 357.727L299.599 357.19L348.544 328.952V271.881H348.53Z"
        fill="#5E5E63"
      />
      <path
        d="M348.549 271.894V272.981L300 301.7L299.604 357.19L298.671 357.726L297.738 357.19L298.12 301.7L248.808 272.953V271.838L248.822 271.823L249.727 271.315L299.067 300.062L347.615 271.329L348.534 271.866V271.88L348.549 271.894Z"
        fill="white"
      />
      <path
        d="M276.577 148.01L275.686 147.502L226.742 119.206L177.783 147.445L176.907 147.939L227.01 177.251L276.605 147.967L276.577 148.01Z"
        fill="#EFEFEF"
      />
      <path
        d="M176.894 147.996V205.053L225.838 233.292L226.771 233.842L227.025 177.266L176.894 147.996Z"
        fill="#28282D"
      />
      <path
        d="M276.592 148.024L226.998 177.308L226.744 233.913L227.69 233.362L276.607 205.124V148.024H276.592Z"
        fill="#5E5E63"
      />
      <path
        d="M276.604 148.067V149.14L228.056 177.844L227.688 233.334L226.741 233.871L225.808 233.334L226.19 177.844L176.864 149.097V148.024L176.878 147.996L176.906 147.982L177.782 147.474L227.122 176.235L275.686 147.502L276.576 148.01L276.59 148.024L276.604 148.067Z"
        fill="white"
      />
      <path
        d="M203.761 271.331L154.817 243.092L105.886 271.331L104.954 271.867V328.924L153.87 357.163L154.789 357.686L155.707 357.149L204.638 328.91V271.881L203.761 271.331Z"
        fill="#EFEFEF"
      />
      <path
        d="M104.954 271.839V328.896L153.87 357.135L154.789 357.657L155.156 301.08L104.954 271.839Z"
        fill="#D7D7D8"
      />
      <path
        d="M155.197 301.15L154.815 357.727L204.678 328.895V271.881L155.197 301.15Z"
        fill="#86868A"
      />
      <path
        d="M204.68 271.882V272.969L156.131 301.688L155.75 357.192L154.831 357.728H154.817L154.789 357.714L153.87 357.192L154.252 301.688L104.954 272.927V271.839L105.886 271.303L155.184 300.064L203.761 271.331L204.68 271.882Z"
        fill="white"
      />
      <path
        d="M348.506 189.281L347.63 188.786L298.671 160.547L249.741 188.786L248.808 189.323L299.067 218.621L348.534 189.351L348.506 189.281Z"
        fill="#EFEFEF"
      />
      <path
        d="M248.808 189.282V246.325L297.738 274.563L298.671 275.114L299.067 218.537L248.808 189.282Z"
        fill="#36363C"
      />
      <path
        d="M348.53 189.312L299.063 218.581L298.667 275.158L299.599 274.607L348.544 246.368V189.312H348.53Z"
        fill="#5E5E63"
      />
      <path
        d="M203.761 188.73L154.817 160.492L105.886 188.73L104.954 189.267V246.31L153.87 274.549L154.817 275.099L155.75 274.549L204.68 246.31V189.31L203.761 188.73Z"
        fill="#EFEFEF"
      />
      <path
        d="M104.954 189.282V246.325L153.87 274.563L154.817 275.114L155.184 218.537L104.954 189.282Z"
        fill="#D7D7D8"
      />
      <path
        d="M155.183 218.581L154.815 275.158L155.748 274.607L204.678 246.368V189.312L155.183 218.581Z"
        fill="#86868A"
      />
      <path
        d="M204.68 189.308V190.396L156.131 219.128L155.75 274.604L154.817 275.155L153.87 274.604L154.252 219.128L104.954 190.367V189.294L105.886 188.744L155.184 217.505L203.761 188.758L204.68 189.308Z"
        fill="white"
      />
      <path
        d="M276.577 313.138L275.687 312.629L226.742 284.391L177.812 312.629L176.894 313.166L226.997 342.464L276.591 313.18L276.577 313.138Z"
        fill="#EFEFEF"
      />
      <path
        d="M176.894 313.136V370.179L225.838 398.418L226.771 398.954L227.025 342.392L176.894 313.136Z"
        fill="#AFAFB1"
      />
      <path
        d="M276.592 313.152L226.998 342.436L226.744 398.999L227.69 398.462L276.607 370.223V313.152H276.592Z"
        fill="#86868A"
      />
      <path
        d="M276.591 230.583L275.673 230.061L226.742 201.821L177.812 230.061L176.894 230.611V287.569L225.838 315.808L226.771 316.345L227.717 315.808L276.634 287.569V230.526L276.591 230.583Z"
        fill="#EFEFEF"
      />
      <path
        d="M176.894 230.565V287.565L225.838 315.805L226.771 316.341L227.152 259.778L176.894 230.565Z"
        fill="#AFAFB1"
      />
      <path
        d="M276.592 230.581L227.125 259.865L226.744 316.427L227.69 315.891L276.607 287.652V230.609L276.592 230.581Z"
        fill="#86868A"
      />
      <path
        d="M276.604 230.61V231.683L228.056 260.402L227.688 315.892L226.741 316.428L225.808 315.892L226.19 260.402L176.864 231.655V230.582L176.878 230.568L177.81 230.017L227.122 258.779L275.672 230.045L276.59 230.568V230.582L276.604 230.61Z"
        fill="white"
      />
      <path
        d="M348.549 189.351V190.41L300 219.142L299.604 274.604L298.671 275.155L297.738 274.604L298.12 219.128L248.808 190.396V189.294L249.741 188.744L299.067 217.519L347.629 188.786L348.506 189.294L348.534 189.308L348.549 189.351Z"
        fill="white"
      />
      <path
        d="M276.604 313.195V314.268L228.056 342.973L227.688 398.462L226.741 398.999L225.808 398.462L226.19 342.973L176.864 314.239V313.152L176.878 313.138V313.124L177.81 312.587L227.122 341.363L275.686 312.63L276.576 313.138L276.59 313.152L276.604 313.195Z"
        fill="white"
      />
      <circle cx="346.605" cy="447.671" r="70.6167" fill="white" />
      <circle cx="346.605" cy="447.671" r="62.8424" fill="#0FF434" />
    </svg>
  );
}

export default function AIChatBot() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <AIChatBotContainer
        title="QUBE AI Chatbot"
        onClick={() => setCollapsed(!collapsed)}
      >
        <QubeBotIcon />
      </AIChatBotContainer>

      <AIChatBotIFrame
        $collapsed={collapsed}
        title="QUBE AI Chatbot"
        src="https://qubeai-v4-vuszzxdixq-as.a.run.app/"
      />
    </>
  );
}
